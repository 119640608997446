<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> レポート - お客様の声一覧</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">部門</label>
                <form-select
                    v-model="condition.division"
                    :options="Division.options()"
                    empty_option="全て"
                ></form-select>
            </div>
            <div class="col-12">
                <label class="form-label">事業部</label>
                <form-select-search-multiple
                    v-model="condition.department_ids"
                    :options="options_department"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-10">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <template v-if="voices.length">

                <div class="table-responsive mb-3" style="width: 100%;">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="table-dark">
                                <th class="text-center text-nowrap">案件</th>
                                <th class="text-center text-nowrap">報告者</th>
                                <th class="text-center text-nowrap">報告日</th>
                                <th class="text-center text-nowrap">場面</th>
                                <th class="text-center text-nowrap">お客様の声</th>
                                <th class="text-center text-nowrap">経緯</th>
                                <th class="text-center text-nowrap">対応</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="voice in voices" :key="voice.activity_id">
                                <tr>
                                    <td class="align-middle text-nowrap px-4">
                                        <router-link :to="{name: 'ProjectEdit', params: {id: voice.project.project_id}}" target="_blank">{{ voice.project?.customer?.company_name }}</router-link>
                                    </td>
                                    <td class="align-middle text-nowrap px-4">{{ voice.user.user_name }}</td>
                                    <td class="align-middle text-nowrap px-4">{{ voice.activity_date }}</td>
                                    <td class="align-middle text-nowrap px-4">{{ voice.voice_scene_label }}</td>
                                    <td class="align-middle prewrap px-4">{{ voice.voice_content }}</td>
                                    <td class="align-middle prewrap px-4">{{ voice.voice_background }}</td>
                                    <td class="align-middle prewrap px-4">{{ voice.voice_action }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-else>
                <p class="alert alert-secondary">※お客様の声がありません</p>
            </template>
        </template>
        <!-- ページネーション -->
        <paginator :meta="paginator" @move="search" class="mt-5"></paginator>
    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInputDate from '@/components/forms/FormInputDate';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import FormSelect from '@/components/forms/FormSelect';
import Paginator from '@/components/tools/Paginator';
import Activity from '@/models/entities/activity';
import Department from '@/models/entities/department';
import CountType from '@/models/enums/count-type';
import Division from '@/models/enums/division';

export default {
    name: 'ReportOrder',
    components: {
        InlineLoader,
        FormInputDate,
        FormSelect,
        Paginator,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // ページネーション
            paginator: {},

            // 検索条件
            condition: {
                division: null,
                department_ids: [],
                date_from: null,
                date_to: null,
                page: 1,
                per_page: null,
            },

            options_department: [],

            // 表示データ
            voices: [],
            order_count: [],
            projects: [],

            // Enum
            CountType,
            Division,
        }
    },
    mounted() {
        this.setInitialDate();
        this.init();
    },
    methods: {
        // 検索期間の初期値設定
        setInitialDate () {
            let date_obj = new Date();
            let to_year = date_obj.getFullYear().toString();
            let to_month = (date_obj.getMonth() + 1).toString().padStart(2, '0');
            let to_date = date_obj.getDate().toString().padStart(2, '0');
            this.condition.date_to = to_year + '-' + to_month + '-' + to_date;

            let from_year = date_obj.getFullYear().toString();
            let from_month = (date_obj.getMonth() + 1).toString().padStart(2, '0');
            this.condition.date_from = from_year + '-' + from_month + '-01';
        },
        async init() {
            await this.fetchDepartments();
            this.search();
        },
        fetchDepartments() {
            return new Promise(resolve => {
                this.loading_count++;
                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        const department = new Department(row);
                        this.options_department.push({value: department.department_id, label: department.department_name});
                    }

                    //事業部未登録の場合
                    if (this.options_department.length === 0) {
                        this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    }
                    if (this.$store.state.auth.department_id) {
                        // 自身の事業部を設定する
                        this.condition.department_ids.push(this.$store.state.auth.department_id);
                    } else {
                        // 所属する事業部がなければ1つ目の事業部を設定
                        this.condition.department_ids.push(this.options_department[0].value);
                    }
                })
                .finally(() => {
                    this.loading_count--;
                    resolve(true);
                })
            })
        },
        // 検索
        search(page = 1) {
            this.condition.page = page;

            // 集計期間バリデーション
            if (this.invalidDate()) {
                return;
            }

            // 受注済み一覧の件数リスト取得
            this.fetchVoices();
        },
        invalidDate() {
            if (
                this.$helper.isBlank(this.condition.date_from)
                || this.$helper.isBlank(this.condition.date_to)
            ) {
                this.showErrorMessage('集計期間を設定してください');
                return true;
            }
        },
        // 受注済み一覧の件数リスト取得
        fetchVoices() {
            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.voices.splice(0);

            this.$http.get('/report/voice', {
                params: this.condition,
            })
            .then(response => {
                this.voices = response.data.data.map((row) => new Activity(row));

                // ページネーション用
                this.paginator = response.data.meta;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
    }
}
</script>

<style scoped>

</style>
